//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

// Re-apply the primer styles with higher specificity
// as the OpenProject form styles override them.
@import 'global_styles/common/checkbox/checkbox.sass'

.Box
  > ul:not(.op-uc-list)
    margin-left: 0


.FormControl
  label
    margin-bottom: 0
  input
    border-radius: var(--borderRadius-medium)

action-menu
  anchored-position
    ul
      margin-left: 0

.UnderlineNav
  @include no-visible-scroll-bar
  margin-bottom: 12px

  @media screen and (min-width: $breakpoint-sm)
    scroll-behavior: smooth

/* Remove margin-left: 2rem from Breadcrumbs */
#breadcrumb,
page-header,
sub-header,
.op-work-package-details-tab-component,
.tabnav
  ol, ul
    margin-left: 0

#breadcrumb
  .breadcrumb-item.breadcrumb-item-selected
    a
      pointer-events: none

.op-primer-adjustments__toggle-switch--hidden-loading-indicator
  .ToggleSwitch-statusIcon
    display: none

.Overlay
  &-body_autocomplete_height
    min-height: 300px

// For some specific cases like progress form in small screens,
// we need to change the direction of elements to column,
// therefore elements are placed below each other
.FormControl-horizontalGroup--sm-vertical
  @media screen and (max-width: $breakpoint-sm)
    .FormControl-horizontalGroup
      flex-direction: column
      row-gap: 1rem

.Button.op-app-header--primer-button,
.Button.op-app-header--primer-button .Button-visual
  color: var(--header-item-font-color)
  width: auto

  &:hover
    background: var(--header-item-bg-hover-color)
    color: var(--header-item-font-hover-color)
  
// Todo: Remove once https://github.com/primer/view_components/pull/3087 is merged
.FormControl-spacingWrapper
  row-gap: var(--stack-gap-normal)

input[type="checkbox"].FormControl-checkbox
  @include new-checkbox
  margin: 0
  &:checked::before
    margin-top: -4px
    margin-left: 1px
    width: 16px
    height: 8px
    transform: none

input[type="radio"].FormControl-radio
  @include new-radio