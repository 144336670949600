@import 'global_styles/common/checkbox/checkbox.sass'

.spot-checkbox
  display: inline-flex
  justify-content: center
  align-items: center
  height: $spot-spacing-1_5
  //width: $spot-spacing-1_5
  flex-shrink: 0

  &:focus-within &--fake
    background-color: #f2f9fa
    border-color: #00a4af

  &, *
    box-sizing: border-box

  &--input-new
    position: fixed
    height: 1px
    width: 1px
    overflow: hidden
    clip: rect(1px,1px,1px,1px)

  &--fake
    position: relative
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: center
    height: 20px
    width: 20px
    margin: 6px 4px
    border: 1.5px solid #ccc
    border-radius: 3px
    appearance: none
    cursor: pointer
    transition: background-color 0.2s ease, border-color 0.2s ease

    &::before,
    &::after
      display: block
      content: ''
    
    &:hover
        background-color: #f2f9fa
        border-color: var(--button--primary-background-hover-color)

  &--input-new:indeterminate + &--fake::before
    background-color: var(--button--primary-background-color)

  &--input-new:checked + &--fake
    display: inline-block
    width: 20px
    height: 20px
    border: 1px solid var(--button--primary-background-color)
    border-radius: 10%
    background-color: var(--button--primary-background-color)
    position: relative

  &--input-new:checked + &--fake::before,
  &--input-new:checked + &--fake::after
    content: ""
    position: absolute
    margin-top: 4px
    margin-left: 3px
    width: 12px
    height: 6px
    border: 2.5px solid #fff
    border-left: none
    border-bottom: none
    transform: rotate(135deg)

  &--input-new:disabled + &--fake
    border-color: var(--button--primary-background-hover-color)
    cursor: default

  &--input-new:disabled:checked + &--fake
    background-color: $spot-color-basic-gray-5

  &--input-new:disabled + &--fake::before,
  &--input-new:disabled + &--fake::after
    background-color: rgb(224, 224, 224)
