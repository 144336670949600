@import 'global_styles/common/checkbox/checkbox.sass'

.op-scrollable-table
  max-width: 100%
  overflow-x: scroll
  
  .op-table
    width: auto

  input[type="checkbox"]
    @include new-checkbox
